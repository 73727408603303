import { Map } from "immutable";
import Cookies from "js-cookie";
import { getCookie } from "~/old-app/utils/helpers/cookieManager";
import enUS from "~/old-app/config/translation/entries/en-US";
import { CookieEnum } from "~/old-app/constants";

export function clearToken() {
  Cookies.remove(CookieEnum.AllKeys);
  Cookies.remove(CookieEnum.Token);
  Cookies.remove(CookieEnum.selectedAddress);
  Cookies.remove(CookieEnum.Location);
  Cookies.remove(CookieEnum.DefaultLocation);
  Cookies.remove(CookieEnum.AddressId);
  Cookies.remove(CookieEnum.SelectedService);
  Cookies.remove(CookieEnum.SelectedServiceName);
  Cookies.remove(CookieEnum.DistrictId);
}

export function getToken() {
  try {
    const idToken = getCookie(CookieEnum.Token);

    return new Map({ idToken });
  } catch (err) {
    clearToken();
    return new Map();
  }
}

export function arrayEqual(array1, array2) {
  return array1.sort().toString() === array2.sort().toString();
}


export function truncateString(str, num = 20) {

  str = str || '';

  let res = str.slice(0, num);

  if (num <= 3) {
    return res+"...";
  }
  else if (str.length <= num) {
    return str;
  }

  else if (str.length > num) {
    return res.slice(0, res.length-3) + "...";
  }

}

export function timeDifference(givenTime) {
  givenTime = new Date(givenTime);
  const milliseconds = new Date().getTime() - givenTime.getTime();
  const numberEnding = (number) => (number > 1 ? "s" : "");
  const number = (num) => (num > 9 ? `${num}` : `0${num}`);
  const getTime = () => {
    let temp = Math.floor(milliseconds / 1000);
    const years = Math.floor(temp / 31536000);
    if (years) {
      const month = number(givenTime.getUTCMonth() + 1);
      const day = number(givenTime.getUTCDate());
      const year = givenTime.getUTCFullYear() % 100;
      return `${day}-${month}-${year}`;
    }
    const days = Math.floor((temp %= 31536000) / 86400);
    if (days) {
      if (days < 28) {
        return `${days} day${numberEnding(days)}`;
      }
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const month = months[givenTime.getUTCMonth()];
      const day = number(givenTime.getUTCDate());
      return `${day} ${month}`;
    }
    const hours = Math.floor((temp %= 86400) / 3600);
    if (hours) {
      return `${hours} hour${numberEnding(hours)} ago`;
    }
    const minutes = Math.floor((temp %= 3600) / 60);
    if (minutes) {
      return `${minutes} minute${numberEnding(minutes)} ago`;
    }
    return "a few seconds ago";
  };
  return getTime();
}

export function stringToInt(value, defValue = 0) {
  if (!value) {
    return 0;
  }
  if (!isNaN(value)) {
    return parseInt(value, 10);
  }
  return defValue;
}
export function stringToPosetiveInt(value, defValue = 0) {
  const val = stringToInt(value, defValue);
  return val > -1 ? val : defValue;
}

export const number_test_float = (n) => {
  var result = n - Math.floor(n) !== 0;
  return result ? true : false;
};
