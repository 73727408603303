import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { RootState } from ".";
import { Country, District } from "../api/country";
import { MethodByCountryConfig, RemoteConfig } from "../api/remote-config";
import { Service } from "../api/service";
import { UserAddress, UserAddressLocations } from "../api/user";
import { CookieEnum } from "../constants";
import { getCookie, setCookie } from "../utils/helpers/cookieManager";

const NAME = "config";
type IConfigState = {
  remoteConfig?: RemoteConfig;
  serviceDetails?: Service;
  services?: Service[];
  countries?: Country[];
  isLoading?: boolean;
  methodByCountryConfig?: MethodByCountryConfig;
  district?: District;

  [CookieEnum.DefaultLocation]?: string;
  [CookieEnum.Location]?: UserAddressLocations;
  [CookieEnum.AddressId]?: number;
  [CookieEnum.SelectedAddress]?: UserAddress;
  [CookieEnum.SelectedService]?: number;
  [CookieEnum.SelectedServiceName]?: string;
  [CookieEnum.DistrictId]?: number;
  [CookieEnum.Language]?: string;
  [CookieEnum.Language]?: string;
  [CookieEnum.SelectedServiceFilterType]?: string;
  [CookieEnum.SelectedServiceQuotation]?: boolean;
  [CookieEnum.SelectedServiceDistrictSupport]?: boolean;
};
const initialState: IConfigState = {
  remoteConfig: undefined,
  methodByCountryConfig: undefined,
  serviceDetails: undefined,
  isLoading: true,
  services: [],
  countries: [],

  [CookieEnum.DefaultLocation]: undefined,
  [CookieEnum.Location]: undefined,
  [CookieEnum.AddressId]: getCookie(CookieEnum.AddressId)
    ? parseInt(getCookie(CookieEnum.AddressId) || "")
    : undefined,
  [CookieEnum.SelectedAddress]: undefined,
  [CookieEnum.SelectedService]: undefined,
  [CookieEnum.SelectedServiceName]: getCookie(CookieEnum.SelectedServiceName),
  [CookieEnum.DistrictId]: getCookie(CookieEnum.DistrictId)
    ? parseInt(getCookie(CookieEnum.DistrictId) || "")
    : undefined,
  [CookieEnum.Language]: getCookie(CookieEnum.Language),
  [CookieEnum.SelectedServiceFilterType]: getCookie(
    CookieEnum.SelectedServiceFilterType
  ),
  [CookieEnum.SelectedServiceQuotation]: getCookie(
    CookieEnum.SelectedServiceQuotation
  )
    ? getCookie(CookieEnum.SelectedServiceQuotation) === "true"
    : undefined,

  [CookieEnum.SelectedServiceDistrictSupport]: getCookie(
    CookieEnum.SelectedServiceDistrictSupport
  )
    ? getCookie(CookieEnum.SelectedServiceDistrictSupport) === "true"
    : undefined,
};
export const ConfigSlice = createSlice({
  name: NAME,
  initialState: initialState,
  reducers: {
    setConfig: (state, action: PayloadAction<IConfigState>) => {
      if (action.payload[CookieEnum.DistrictId]) {
        setCookie(CookieEnum.DistrictId, action.payload[CookieEnum.DistrictId]);
      }

      return { ...state, ...action.payload };
    },

    setAddressId: (state, action: PayloadAction<number>) => {
      state[CookieEnum.AddressId] = action.payload;
    },
    setDefaultLocation: (state, action: PayloadAction<string>) => {
      state[CookieEnum.DefaultLocation] = action.payload;
    },
    setLocation: (state, action: PayloadAction<UserAddressLocations>) => {
      state[CookieEnum.Location] = action.payload;
    },
    setSelectedAddress: (state, action: PayloadAction<UserAddress>) => {
      state[CookieEnum.SelectedAddress] = action.payload;
    },
    setSelectedService: (state, action: PayloadAction<number>) => {
      state[CookieEnum.SelectedService] = action.payload;
    },
    setSelectedServiceName: (state, action: PayloadAction<string>) => {
      state[CookieEnum.SelectedServiceName] = action.payload;
    },
    setDistrictId: (state, action: PayloadAction<number>) => {
      setCookie(CookieEnum.DistrictId, action.payload.toString());
      state[CookieEnum.DistrictId] = action.payload;
    },
    clearConfig: (state) => {
      return { ...initialState };
    },
    setRemoteConfig: (state, action: PayloadAction<RemoteConfig>) => {
      const remoteConfig = action.payload;

      state.remoteConfig = remoteConfig;
      state.isLoading = false;
      state[CookieEnum.DistrictId] = getCookie(CookieEnum.DistrictId)
        ? parseInt(getCookie(CookieEnum.DistrictId) + "")
        : remoteConfig?.default_district_id;
      state[CookieEnum.SelectedService] = remoteConfig?.default_service_id;
    },

    setService: (state, action: PayloadAction<Service>) => {
      state.serviceDetails = action.payload;
      state[CookieEnum.SelectedServiceName] = action.payload?.name;
      state[CookieEnum.SelectedServiceQuotation] =
        !!action.payload?.quotation_enabled;
      state[CookieEnum.SelectedServiceFilterType] = action.payload?.filter_type;
      state[CookieEnum.SelectedServiceDistrictSupport] =
        !!action.payload?.is_support_district;
    },

    setMethodByCountryConfig: (
      state,
      action: PayloadAction<MethodByCountryConfig>
    ) => {
      state.methodByCountryConfig = action.payload;
    },

    setLanguage: (state, action: PayloadAction<string>) => {
      state[CookieEnum.Language] = action.payload;
    },

    setSelectedServiceFilterType: (state, action: PayloadAction<string>) => {
      state[CookieEnum.SelectedServiceFilterType] = action.payload;
    },

    setServices(state, action: PayloadAction<Service[]>) {
      state.services = action.payload;
    },

    setCountries(state, action: PayloadAction<Country[]>) {
      state.countries = action.payload;
    },

    setDistrict(state, action: PayloadAction<District>) {
      state.district = action.payload;
    },
  },
});

export const ConfigActions = ConfigSlice.actions;
export const ConfigSelector = (state: RootState) => state.config;

export const ConfigReducer = ConfigSlice.reducer;

export const useConfigSlice = () => useSelector(ConfigSelector);
// export const useConfigSlice = () => useSelector(ConfigSelector, shallowEqual);
