import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from ".";
import { Influencer } from "../api/influencer";
import { LoginChannel, User } from "../api/user";
import { clearToken } from "../utils/helpers/utility";

import { persistReducer } from "redux-persist";
import localStorage from "redux-persist/es/storage";

import { CookieStorage } from 'redux-persist-cookie-storage'
import Cookies from "js-cookie";
import { ConfirmationResult } from "firebase/auth";

// import storage from "redux-persist/lib/storage";

const NAME = "auth";
type IAuthState = {
  user?: User | Influencer;
  accessToken?: string;
  refreshToken?: string;
  isInfluencer?: boolean;
  role?: string;
  status?: "register" | "verifyOtp" | "signIn" | "pickAddress";
  channel?: LoginChannel;
  isExistAccount?: boolean;
  mobile?: string;
  code?: string;
  language?: string;
  countryId?: number;
  phone_code?: string;
  fullPhone?: string;
  firebaseOtpResponse?: ConfirmationResult;
};
const initialState: IAuthState = {
  user: undefined,
  accessToken: "",
  refreshToken: "",
  isInfluencer: false,
  role: "user",
  status: "signIn",
  isExistAccount: false,
  channel: "sms",
  mobile: "",
  code: "",
  language: "en",
  countryId: undefined,
  phone_code: "",
  fullPhone: "",

  firebaseOtpResponse: undefined,
};
export const AuthSlice = createSlice({
  name: NAME,
  initialState: initialState,
  reducers: {
    login: (
      state,
      action: PayloadAction<
        Pick<IAuthState, "user" | "accessToken" | "role" | "isExistAccount">
      >
    ) => {
      const accessToken = action.payload?.accessToken;
      const user = action.payload?.user;
      const isInfluencer = action.payload?.role === "influencer";

      return { ...state, accessToken, user, isInfluencer };
    },

    signup: (state, action) => {
      const accessToken = action.payload?.accessToken;
      const user = action.payload?.user;

      return { ...state, accessToken, user };
    },
    logout: (state) => {
      clearToken();
      return {
        ...state,
        ...initialState,
      };
    },

    setUser: (
      state,
      { payload: { ...props } }: PayloadAction<Pick<IAuthState, "user">>
    ) => {
      return { ...state, ...props };
    },
    setLoginLayoutType: (
      state,
      { payload }: PayloadAction<Pick<IAuthState, "accessToken">>
    ) => {
      return { ...state };
    },

    setChannel: (
      state,
      { payload }: PayloadAction<Pick<IAuthState, "channel">>
    ) => {
      return { ...state, channel: payload.channel };
    },

    setStatus: (
      state,
      { payload }: PayloadAction<Pick<IAuthState, "status">>
    ) => {
      return { ...state, status: payload.status };
    },

    setIsExistAccount: (
      state,
      { payload }: PayloadAction<Pick<IAuthState, "isExistAccount">>
    ) => {
      return { ...state, isExistAccount: payload.isExistAccount };
    },

    setMobile: (
      state,
      {
        payload,
      }: PayloadAction<Pick<IAuthState, "mobile" | "phone_code" | "fullPhone">>
    ) => {
      return {
        ...state,
        mobile: payload.mobile,
        phone_code: payload.phone_code,
        fullPhone: payload.fullPhone,
      };
    },

    setCode: (state, { payload }: PayloadAction<Pick<IAuthState, "code">>) => {
      return { ...state, code: payload.code };
    },

    setLanguage: (
      state,
      { payload }: PayloadAction<Pick<IAuthState, "language">>
    ) => {
      return { ...state, language: payload.language };
    },

    setCountryId: (
      state,
      { payload }: PayloadAction<Pick<IAuthState, "countryId">>
    ) => {
      return { ...state, countryId: payload.countryId };
    },

    setFirebaseOtpResponse: (
      state,
      { payload }: PayloadAction<Pick<IAuthState, "firebaseOtpResponse">>
    ) => {
      return { ...state, firebaseOtpResponse: payload.firebaseOtpResponse };
    },
  },
});

export const AuthActions = AuthSlice.actions;
export const authSelector = (state: RootState) => state.auth;

// export const AuthReducer = AuthSlice.reducer;
//     // TODO: Fix the type of storage
export const AuthReducer = persistReducer(
  {
    storage: new CookieStorage(Cookies /*, options */),
    // storage: localStorage,
    key: NAME,
    debug: false,
    whitelist: ["user", "accessToken", "isInfluencer", "role"],
  },
  AuthSlice.reducer
);

export const useAuthSlice = () => useSelector(authSelector, shallowEqual);
