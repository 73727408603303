{
  "global.copyRights": "Matjr. All Rights Reserved",
  "global.anySearchPlaceholder": "What Do You Want To Learn!",
  "global.anySearchPlaceholderfooter": "Search for anything",
  "global.fieldAdd": "please add search text",
  "global.showMore": "show more",
  "global.amTeacher": "I'm an Instructor",
  "global.myproducts": "My products",
  "global.login": "Log in",
  "global.loginOrRegister": "Log in / Register",
  "global.register": "Register",
  "global.signUp": "Sign Up",
  "global.logout": "Logout",
  "global.arabic": "Arabic",
  "global.english": "English",
  "global.all": "All",
  "global.addToCart": "Add to cart",
  "global.cart": "Cart",
  "global.lang": "Language",
  "global.getProduct": "Get Product",
  "global.startLearning": "Let's start learning,",
  "global.learnNext": "What to learn next",
  "global.currentCurrency": "EGP",
  "global.currency": "Currency",
  "global.noProductsTitle": "no products",
  "global.noProductsSubtitle": "there are no products available at the moment, check again later",
  "global.noBranchesTitle": "no branches",
  "global.noBranchesSubtitle": "there are no branches available at the moment, check again later",
  "global.noBrandsTitle": "no brands",
  "global.noBrandsSubtitle": "there are no brands available at the moment, check again later",
  "global.free": "Free",
  "global.paid": "Paid",
  "global.price": "Price",
  "global.categories": "Categories",
  "global.services": "Services",
  "global.other": "Other",
  "global.close": "Close",
  "global.selectService": "Select A Service",
  "global.selectCategory": "Select A Category",
  "global.accountOptions": "Account Options",
  "global.hideFilters": "Hide Filters",
  "global.showFilters": "Show Filters",
  "global.levels": "Levels",
  "global.languages": "Languages",
  "global.durations": "Durations",
  "global.noOrdersTitle": "no orders",
  "global.noOrdersSubtitle": "you haven't made any orders yet, start shopping now",

  "global.youMayLike": "You might also like",
  "global.emptyDescription": "no data available",
  "global.moreproducts": "more products",

  "global.countryLabel": "Country",
  "global.countryPlaceholder": "Select Country",
  "global.cancel": "Cancel",
  "global.start": "Start",
  "global.startProduct": "Start Product",
  "global.required": "This field is required",
  "global.tooShort": "Too Short! The minimum character is {len}",
  "global.passwordsMustMatch": "Password and confirm password doesn't match.",
  "global.invalidEmail": "Email Format is wrong right format is Mail@Company.com",
  "global.account": "Account",
  "global.save": "Save",
  "global.loadMore": "Load More",
  "global.addReview": "Add Review",
  "global.edit": "Edit",
  "global.change": "Change",
  "global.delete": "Delete",
  "global.in": "In",
  "global.out": "Out",
  "global.newest": "Newest",
  "global.oldest": "Oldest",
  "global.product": "Product",
  "global.exam": "Exam",
  "global.wallet": "Wallet",
  "global.student": "Student",
  "global.actions": "Actions",
  "global.searchQuestions": "Search all product question",
  "global.onlyNumber": "only numbers allowed",
  "global.onlyUrl": "only url allowed",
  "global.tooLong": "Too long! The maximum character is {len}",
  "global.uploadImage": "Upload Image",
  "global.goToProduct": "Go to Product",
  "global.new": "New",
  "global.noOptions": "No Options Available",
  "global.greaterZero": "must be greater than 0",
  "global.withdraw": "Withdraw",
  "global.search": "Search",
  "global.favourite": "Favourite",
  "global.addToFav": "Add To Favourite",
  "global.matjr": "Matjr",
  "global.settings": "Settings",
  "global.myOrders": "Orders",
  "global.addresses": "Addresses",
  "global.returns": "Returns",
  "global.profile": "Profile",
  "global.emptyReviews": "no reviews available",
  "global.phoneInvalid": "Phone Number is Invalid",
  "global.select": "Select",
  "global.from": "From",
  "global.to": "To",
  "global.priceRange": "Price Range",
  "global.home": "Home",
  "global.clear": "Clear",
  "global.next": "Next",
  "global.prev": "Prev",
  "global.confirm": "Confirm",
  "global.pleaseChooseAddress": "Please Choose An Address",

  "footer.slogan": "Design amazing digital experiences that create more happy in the world.",
  "footer.getAppTitle": "Get the app",
  "footer.about": "About",
  "footer.privacy": "Privacy Policy",
  "footer.terms": "Terms and Conditions",
  "footer.return": "Return Policy",
  "footer.warrenty": "Warranty Policy",
  "footer.maintenance": "Maintenance Policy",
  "footer.contact": "Contact us",
  "footer.policies": "Store Policies",
  "footer.contactInfo": "Contact Information",
  "footer.findUs": "Find Us On Map",

  "navbars.exploreServices": "Explore Services",
  "navbars.switchedTo": "Switched To",
  "navbars.cart": "Cart",

  "home.categoryShoppingTitle": "Shop By Category",
  "home.hotDealsSectionTitle": "Hot Deals",
  "home.bestSellingSectionTitle": "Best Seller",
  "home.popularSectionTitle": "Most Popular",
  "home.newSectionTitle": "New Arrived",
  "home.brandsSectionTitle": "Brands",
  "home.shippingTitle": "Fast Delivery",
  "home.shippingDesc": "Get your needs in two days.",
  "home.accessabilityTitle": "One-Click Safe Shopping",
  "home.accessabilityDesc": "Save your payment and address information, and shop securely.",
  "home.availabilityTitle": "Mobile in your pocket",
  "home.availabilityDesc": "Enjoy safe shopping from anywhere you want. Return at Door.",
  "home.returnsTitle": "Return at Door",
  "home.returnsDesc": "Returning your purchased product has never been easier.",

  "product.stars": "Stars",
  "product.star": "Star",
  "product.ratings": "Ratings",
  "product.rating": "Rating",
  "product.seller": "Seller",
  "product.categories": "Categories",
  "product.left": "left",
  "product.by": "by",
  "product.outOf5": "out of 5",
  "product.remove": "Remove",
  "product.writeReview": "Write a review",
  "product.quantity": "Quantity",
  "product.qty": "QTY",
  "product.returnReasonLabel": "Return Reason",
  "product.returnReasonplaceholder": "Select Return Reason",
  "product.commentRequiredHint": "this reason require a comment",
  "product.returnCommentsLabel": "Return Comments",
  "product.similarProducts": "Similar/Related Products ",
  "product.productDescription": "Product Description",
  "product.productSpecifications": "Product Specifications",
  "product.productReviews": "Product Reviews",
  "product.selectOption": "Select An Option",
  "product.deleteOldCartMessage": "There are items from different shop in your cart. Would you like to empty your cart to add this item?",
  "product.emptyCart": "Empty Cart?",

  "filter.sortBy": "Sort By",
  "filter.recommended": "Recommended",
  "filter.bestSelling": "Best Selling",
  "filter.ascending": "Ascending A-Z",
  "filter.descending": "Descending Z-A",
  "filter.highestPrice": "Highest Price",
  "filter.lowestPrice": "Lowest Price",
  "filter.newest": "Newest",
  "filter.oldest": "Oldest",
  "filter.filterBy": "Filter By",
  "filter.resultsFor": "Results For",
  "filter.view": "View",
  "filter.display": "Display",
  "filter.perPage": "Per Page",
  "filter.categories": "Categories",
  "filter.allCategories": "All",
  "filter.exploreServices": "Explore Services",
  "filter.brands": "Brands",
  "filter.rating": "Rating",
  "filter.productStock": "Product Stock",
  "filter.inStock": "In Stock",
  "filter.outOfStock": "Out Of Stock",
  "filter.preOrder": "Pre Order",
  "filter.sort": "Sort",
  "filter.filter": "Filter",
  "filter.all": "All",
  "filter.andMore": "And More",
  "filter.andLess": "And Less",
  "filter.minimumBasketAmount": "Minimum Basket Amount",
  "filter.topRated": "Top Rated",
  "filter.fastDelivery": "Fast Delivery",
  "filter.discountRate": "Discount Rate",
  "filter.distance": "Distance",
  "filter.rate": "Rate",
  "filter.noSubcategories": "No subcategories available at the moment for this category",

  "cart.shoppingCart": "Shopping Cart",
  "cart.productsInCart": "products In Cart",
  "cart.orderSummary": "Order Summary",
  "cart.subtotal": "Subtotal",
  "cart.tax": "Tax",
  "cart.total": "Total",
  "cart.policyLabel": "Please check to acknowledge our <Link className='text-primary'>Privacy & Terms Policy</Link>",
  "cart.policyMsg": "Accept Privacy & Terms Policy is required",
  "cart.checkout": "Checkout",
  "cart.couponDiscount": "Coupon Discount",
  "cart.shippingFees": "Shipping Fees",
  "cart.notificationTitle": "Important Notification for cart products",
  "cart.savedForLater": "Saved For Later",
  "cart.proceed": "Proceed",
  "cart.addToFavorites": "Add To Favorites",
  "cart.removeFromFavorites": "Remove From Favorites",
  "cart.moreNotifications": "See More Notifications",
  "cart.outOfStock": "Out Of Stock",

  "checkout.quantity": "Quantity",
  "checkout.nameOnCardLabel": "Name On Card",
  "checkout.nameOnCardPlaceholder": "Your Card Name",
  "checkout.cardNumberLabel": "Card number",
  "checkout.cardNumberPlaceholder": "Enter your card number",
  "checkout.cvvLabel": "cvv",
  "checkout.cvvPlaceholder": "cvv",
  "checkout.expiryLabel": "Expiration date",
  "checkout.yearLabel": "Expiration / year",
  "checkout.yearPlaceholder": "Ex: 23",
  "checkout.monthLabel": "Expiration / month",
  "checkout.year": "Year",
  "checkout.month": "Month",
  "checkout.expiryPlaceholder": "MM / YY",
  "checkout.orderSummary": "Order Summary",
  "checkout.completeCheckout": "Complete Checkout",
  "checkout.order": "Order",
  "checkout.requiredMsg": "Required",
  "checkout.cardNumberMsg": "Card Number must be 16 numbers long",
  "checkout.cvcMsg": "CVV must be 3 numbers long",
  "checkout.yearMsg": "Year must be 2 numbers long",
  "checkout.note": "Note",
  "checkout.numberFormat": "Accepts only numbers",
  "checkout.checkoutConfirmTitle": "Confirm Privacy & Terms Policy",
  "checkout.checkoutConfirmDescription": "lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap int",
  "checkout.iAgree": "I agree to the ",
  "checkout.termsPolicy": "Terms of Use, Refund Policy, and Privacy Policy",
  "checkout.paymentFailed": "Payment Failed",
  "checkout.orderPlacedSuccessfully": "Order Placed Successfully",
  "checkout.payment": "Payment",
  "checkout.address": "Shipping Address",
  "checkout.orderReview": "Order Review",
  "checkout.ccPayment": "Credit Card Payment",
  "checkout.cashPayment": "Cash Payment",
  "checkout.useWalletBalance": "Use Wallet Balance First",
  "checkout.orderPlacedFailedFullyMsg": "Sorry, your order has been failed to place, please try again later.",
  "checkout.orderPlacedSuccessfullyMsg": "Thank you for your purchase. Your order has been placed successfully and will be shipped soon.",
  "checkout.continueShopping": "Continue Shopping",
  "checkout.reviewOrderStatus": "Review Order Status",
  "checkout.shippingAddress": "Shipping Address",
  "checkout.noAddresses": "No Addresses",
  "checkout.selectAddress": "selectAddress",
  "checkout.noDefaultAddress": "No Default Address",
  "checkout.moreAddresses": "More Addresses",
  "checkout.walletBalance": "Wallet Balance",
  "checkout.addCoupon": "Add Coupon",
  "checkout.apply": "Redeem",
  "checkout.changeCoupon": "Change Coupon",
  "checkout.removeCoupon": "Remove Coupon",
  "checkout.enterCoupon": "Enter Coupon",
  "checkout.coupon": "Coupon",
  "checkout.couponSuccessMsg": "applied successfully! You saved",
  "checkout.deleteCouponSuccessMsg": "Coupon deleted successfully! Add new coupon",
  "checkout.balanceNotSuffisant": "Balance Not Sufficient",
  "checkout.backToCart": "Back To Cart",

  "successfull.greatChoice": "Great Choice",
  "successfull.share": "Share This Product",
  "successfull.bestsellers": "Bestsellers in UI",
  "successfull.topProduct": "Top product in UI",

  "landing.enterShippingLocation": "Enter Shipping Location",
  "landing.introTitle": "Food or grocery, all your needs are at your doorstep!",
  "landing.introDescription": "Powerful, self-serve product and growth analytics to help you convert, engage, and retain more users. Trusted by over 4,000 startups.",
  "landing.downloadTitle": "Your everyday, right away",
  "landing.downloadDescription": "Order food and grocery delivery online from hundreds of restaurants and shops nearby.",

  "maps.locationsModalTitle": "What's your exact location?",
  "maps.locationsModalDescription": "Providing your location enables more accurate search and delivery ETA, seamless order tracking and personalised recommendations.",
  "maps.findChooseLocation": "Find and choose a location*",
  "maps.locationNotSupported": "Location not supported",
  "maps.chooseLocation": "Choose a location",
  "maps.pickThisAddress": "Pick this address",
  "maps.startTyping": "Start Typing",
  "maps.noResultsFound": "No Location Found",
  "maps.searching": "Searching",
  "maps.pleaseChooseLocation": "Please Choose Location",

  "auth.methodMsg": "Select preferred method to verify phone number",
  "auth.or": "OR",
  "auth.viaSms": "Continue Via Sms",
  "auth.viaWhatsapp": "Continue Via Whatsapp",
  "auth.mobileLabel": "Phone number",
  "auth.logIn": "Login",
  "auth.otpSent": "Otp sent to your mobile",
  "auth.verifyOtp": "Verify Your Otp",
  "auth.verifyOtpDescription": "Enter the 4 digit code sent to the registered mobile number.",
  "auth.didNotReceive": "Did not receive a code ?",
  "auth.resend": "Resend",
  "auth.wait": "Wait",
  "auth.resendMsg": "seconds before resending!",
  "auth.verify": "Verify",
  "auth.verifyWarning": "Don't share the verification code with anyone !",
  "auth.signUp": "Sign up",
  "auth.fullNameLabel": "Full Name",
  "auth.fullNamePlaceholder": "Enter your full name",
  "auth.emailAddressLabel": "Email",
  "auth.emailAddressPlaceholder": "Enter your email",
  "auth.referralCodeLabel": "Referral Code",
  "auth.referralCodePlaceholder": "Enter Valid Referral Code",
  "auth.iAgree": "I agree to the ",
  "auth.termsConditions": "Terms & conditions",
  "auth.createAccount": "Create account",
  "auth.backToLogIn": "Back to log in",
  "auth.otpVerified": "Otp verified successfully",
  "auth.otpVerifiedRegister": "Otp verified, Complete your registration",

  "auth.passwordLabel": "Password",
  "auth.passwordPlaceholder": "Enter Valid Password",
  "auth.passwordValidation": "Use 8 or more characters with a mix of letters, numbers & symbols",
  "auth.confirmPasswordLabel": "Password Confirmation",
  "auth.confirmPasswordPlaceholder": "Enter Password Confirmation",

  "auth.alreadyOn": "Already on Matjr?",
  "auth.successSignUpTitle": "Sign Up Success!",
  "auth.successSignUpDescription": "You have signed up successfully",
  "auth.verifyAccountTitle": "Verify your email",
  "auth.sentConfirmEmail": "We have sent a confirmation email link to ",
  "auth.checkEmailValidate": ", please check your email to validate your account. ",
  "auth.clickHere": "Click here",
  "auth.didnotReceiveEmail": "if you didn't receive an email",
  "auth.accountCreated": "Account Created Successfully",
  "auth.verificationReSent": "verification link re-sent to your email",
  "auth.return": "Return",
  "auth.toLogin": "to Login",
  "auth.emailVerified": "Email verified Successfully!",
  "auth.enterPassword": "Enter your password",
  "auth.signInTitle": "Welcome back",
  "auth.forgotPassword": "Forgot password?",
  "auth.newMatjr": "New in Matjr?",
  "auth.signUpButton": "Sign up",
  "auth.loggedSuccessfully": "Logged in Successfully",
  "auth.rememberMe": "Remember Me",
  "auth.emailSentTitle": "Email has been sent",
  "auth.emailSentDescription": "Please check your email inbox for a password recovery link.",
  "auth.emailSentSecDescription": "Don't forget to check your spam folder.",
  "auth.donotFindEmail": "Didn't find the email?",
  "auth.resendNow": "Resend Now",
  "auth.forgotPasswordTitle": "Forgot password",
  "auth.forgotPasswordDescription": "Enter the email address you use on Matjr, We'll send you a link to reset your password.",
  "auth.resetPasswordButton": "Reset Password",
  "auth.resetPasswordSent": "Your reset password link and instruction have been sent to Entered Email.",
  "auth.resetPasswordTitle": "Reset Password",
  "auth.newPasswordLabel": "Create new password",
  "auth.newPasswordPlaceholder": "New Password",
  "auth.ReEnterNewPasswordLabel": "Re-enter new password",
  "auth.ReEnterNewPasswordPlaceholder": "Re-enter password",
  "auth.successResetTitle": "Successful password reset!",
  "auth.successResetDescription": "Your password has been successfully reset. Now you can login with the new password.",

  "product.aboutProduct": "About this product",
  "auth.passwordUpdated": "Password has been changed successfully, you can now log in with a new Password.",
  "auth.invalidPassword": "Invalid password Pattern EX : Aa123456",

  "product.about": "About",
  "product.productContent": "Product Content",
  "product.instructors": "Instructors",
  "product.learnerReviews": "Learner reviews",
  "product.whatIncluded": "WHAT’S INCLUDED",
  "product.interestedproducts": "Other products you might be interested in",
  "product.learners": "learners",
  "product.createdBy": "Created by",
  "product.lastUpdated": "Last updated",
  "product.addCart": "Add to cart",
  "product.buyNow": "Buy Now",
  "product.previewProduct": "Preview this product",
  "product.whatLearn": "WHAT YOU WILL LEARN",
  "product.relatedproducts": "Related products",
  "product.moreSections": "more Sections",
  "product.moreDetails": "See More Details",
  "product.lessDetails": "See Less Details",
  "product.moreReviews": "See More Reviews",
  "product.preOrder": "Pre-Order",
  "product.weight": "Weight",
  "product.dimensions": "Dimensions",
  "product.length": "Length",
  "product.width": "Width",
  "product.height": "Height",
  "product.home": "Home",
  "product.products": "Products",
  "product.noAvailableStock": "There is not enough stock available.",
  "product.unavailableItem": "This item is unavailable",
  "product.deliveryUnavailable": "We Don't Deliver To Your Location",
  "product.off": "Off",
  "product.saveForLater": "Save For Later",
  "product.viewReturnStatus": "View Return/Refund Status",

  "product.hoursTitle": "hours",
  "product.examTitle": "One exam at the end of the product",
  "product.lifetimeTitle": "Full lifetime access",
  "product.accessibilityTitle": "Access on tablet and phone",
  "product.certificateTitle": "Certificate of completion",
  "product.shareableTitle": "Shareable Certificate",
  "product.shareableDescription": "Get a certificate after passing the exam",
  "product.onlineTitle": "100% online products",
  "product.onlineDescription": "Start instantly and learn at your own schedule.",
  "product.scheduleTitle": "Flexible Schedule",
  "product.scheduleDescription": "Set and maintain flexible deadlines.",
  "product.degreeDescription": "No degree or prior experience required",
  "product.approximatelyTitle": "Approximately 6 months to complete",
  "product.approximatelyDescription": "Suggested pace of 10 hours/week",
  "product.subtitleDescription": "Subtitles:",

  "product.Video": "Video",
  "product.min": "min",
  "product.goToProduct": "Go to Product",
  "product.freeVideos": "Free sample videos:",
  "product.addedToCart": "added to cart",
  "product.intro": "Intro",
  "product.minQtyReached": "Min Quantity Reached",
  "product.maxQtyReached": "Max Quantity Reached",

  "instructor.myproducts": "My products",
  "instructor.instructor": "Instructor",
  "instructor.totalStudents": "Total students",
  "instructor.reviews": "Reviews",
  "instructor.aboutMe": "About me",
  "instructor.website": "Website",
  "instructor.linkedin": "Linkedin",
  "instructor.twitter": "Twitter",

  "myproducts.allProduct": "All Product",
  "myproducts.wishlist": "Wishlist",
  "myproducts.myproducts": "My products",

  "faq.faqs": "Faqs",
  "faq.moreFaqs": "more Faqs",

  "account.account": "Account",
  "account.myOrders": "My Orders",
  "account.myFavourites": "My Favourites",
  "account.myReviews": "My Reviews",
  "account.myWallet": "My Wallet",
  "account.personalInformation": "Personal Information",
  "account.myAddress": "My Address",
  "account.useAddress": "Use Address",
  "account.myCards": "My Cards",
  "account.profile": "Profile",
  "account.settings": "Settings",
  "account.country": "Country",
  "account.languages": "Languages",
  "account.matjrApp": "Matjr App",
  "account.aboutAbout": "About App",
  "account.contactUs": "Contact Us",
  "account.support": "Support",
  "account.current": "Current",
  "account.completed": "Completed",
  "account.rejectedLabel": "Rejected",
  "account.cancellations": "Cancellations",
  "account.return": "Returns",
  "account.searchProduct": "Search for product name or brand",
  "account.moreOrders": "See more Orders",
  "account.draft": "Draft",
  "account.pending": "pending",
  "account.processing": "processing",
  "account.shipped": "shipped",
  "account.outForDelivery": "out_for_delivery",
  "account.delivered": "delivered",
  "account.inReturn": "in_return",
  "account.returnReceived": "return_received",
  "account.returned": "returned",
  "account.onHold": "on_hold",
  "account.rejected": "rejected",
  "account.cancelled": "cancelled",
  "account.noOrders": "no orders available",
  "account.orderNumber": "Order Number",
  "account.returnNumber": "Return Number",
  "account.orderRate": "Order rate",
  "account.paymentMethod": "Payment method",
  "account.price": "Price",
  "account.returnOrder": "Return order",
  "account.cancelOrder": "cancel order",
  "account.ordersRate": "Orders rate",
  "account.reviewLabel": "Review",
  "account.reviewPlaceholder": "How was your experience with the product? What should others know before purchasing the product? How did you use the product?",
  "account.send": "Send",
  "account.titleLabel": "Title",
  "account.descriptionLabel": "Description",
  "account.email": "Email",
  "account.phone": "Phone",
  "account.address": "Address",
  "account.mapLocation": "Map Location",
  "account.findUs": "Find Us",

  "account.orderDetail": "Order Detail",
  "account.orderDate": "Order Date",
  "account.orderSummary": "Order Summary",
  "account.product": "Product",
  "account.store": "Store",
  "account.paymentInformation": "Payment Information",
  "account.subtotal": "Subtotal",
  "account.deliveryFee": "Delivery Fee",
  "account.discountCoupon": "Discount Coupon",
  "account.walletBalance": "Wallet Balance",
  "account.total": "Total",
  "account.shippingInformation": "Shipping Information",
  "account.addressType": "Address type",
  "account.returnProducts": "Return Products",
  "account.trackOrder": "Track Order",
  "account.returnProduct": "Return Product",
  "account.selectItems": "Select the items you want to return",
  "account.refundMethodButton": "Continue to Refund Method",
  "account.refundMethod": "Refund method",
  "account.chooseRefundMethod": "Choose a Refund method",
  "account.walletMethod": "Wallet",
  "account.originalPaymentMethod": "Original Payment method",
  "account.returnPickupButton": "Continue to Return Pickup",
  "account.returnPickup": "Return Pickup",
  "account.howWillYouSend": "How will you send a return request?",
  "account.pickupAddress": "Pickup Address",
  "account.changeAddress": "Change Address",
  "account.dateAndTime": "Date and time",
  "account.summaryButton": "Continue to Summary and Confirmation",
  "account.selectAddress": "Select Address",
  "account.selectedSuccessfully": "Address Selected Successfully",
  "account.phoneNumber": "Phone Number",
  "account.close": "Close",
  "account.returnSummary": "Return Summary",
  "account.confirmReturn": "Confirm Return",
  "account.totalRefund": "Total Refund",
  "account.refundInformation": "Refund Information",
  "account.returnOrders": "Return Orders",
  "account.returnDetail": "Return Details",
  "account.idRequired": "ID is required",
  "account.quantityRequired": "Quantity is required",
  "account.reasonRequired": "Reason is required",
  "account.commentLong": "Too long! The maximum character is 200 in Comment",
  "account.chooseOne": "you should at least choose a product to return",
  "account.commentRequired": "Comment is required",
  "account.noAddress": "no address available",
  "account.moreAddress": "more addresses",
  "account.moreFavorite": "more favorite",
  "account.tax": "Tax",
  "account.cancelConfirmation": "Cancel Confirmation",
  "account.cancelDesc": "Order {{id}} will be cancelled, and refunded will made to original payment method if online payment, Do you want to change to \"Cancel Order\"",
  "account.confirm": "Confirm",
  "account.cancel": "Cancel",
  "account.printInvoice": "Print Invoice",
  "account.submit": "Submit",

  "account.addNewAddress": "Add New Address",
  "account.addressTypeLabel": "Address Type",
  "account.addressName": "Address Name",
  "account.EnterAddressName": "Enter Address Name",
  "account.contactName": "Contact Name",
  "account.enterContactName": "Enter Contact Name",
  "account.phoneNumberLabel": "Phone Number",
  "account.enterPhoneNumber": "Enter Phone Number",
  "account.countryLabel": "Country",
  "account.selectCountry": "Select Country",
  "account.regionState": "Region/state",
  "account.selectRegionState": "Select Region/state",
  "account.city": "City",
  "account.selectCity": "Select City",
  "account.addressLabel": "Address",
  "account.enterAddress": "Enter Address",
  "account.buildingNumber": "Building Number",
  "account.enterBuildingNumber": "Enter Building Number",
  "account.floor": "Floor",
  "account.enterFloor": "Enter Floor",
  "account.apartmentNumber": "Apartment Number",
  "account.enterApartmentNumber": "Enter Apartment Number",
  "account.selectAddressOnMap": "Select Address On Map",
  "account.postalCode": "Postal Code",
  "account.enterPostalCode": "Enter Postal Code",
  "account.setAsDefaultAddress": "Set as default address",
  "account.addAddress": "Add Address",
  "account.edit": "Edit",
  "account.delete": "Delete",
  "account.wantDelete": "Do you want to delete",
  "account.yes": "Yes",
  "account.no": "No",
  "account.cannotDelete": "You cannot delete your default address. Please select a different address as your default address before deleting this one.",
  "account.editAddress": "Edit Address",
  "account.home": "Home",
  "account.work": "Work",
  "account.other": "Other",
  "account.nameLabel": "Name",
  "account.ProfilePhotoLabel": "Profile Photo",
  "account.namePlaceholder": "Enter Client Name",
  "account.emailLabel": "Email",
  "account.companyNameLabel": "Company Name",
  "account.companyNamePlaceholder": "Enter Company Name",
  "account.dateLabel": "Birthdate",
  "account.datePlaceholder": "Select Birthdate",
  "account.genderLabel": "Gender",
  "account.genderPlaceholder": "Select Gender",
  "account.save": "Save",
  "account.female": "Female",
  "account.male": "Male",
  "account.changePassword": "Change Password",
  "account.currentPassowrdLabel": "Current Passowrd",
  "account.currentPassowrdPlaceholder": "Enter Current Password",
  "account.newPasswordLabel": "New Password",
  "account.newPasswordLPlaceholder": "Enter New Passoword",
  "account.reEnterNewPasswordLabel": "Re-enter New Password",
  "account.reEnterNewPasswordPlaceholder": "Re-Enter New Password",
  "account.preferences": "Preferences",
  "account.defaultLanguageLabel": "Language",
  "account.receiveNotification": "Receive Notification from",
  "account.buyAgain": "Buy Again",
  "account.emptyProduct": "You don't have any product",
  "account.continueShopping": "Continue shopping",
  "account.similarProduct": "Similar Product",
  "account.topSelling": "Top Selling",
  "account.view": "View",
  "account.noCreditsTitle": "No credits available",
  "account.noCreditsDescription": "Credits and cashback you accumulate from any marketplace on Matjr will display here",
  "account.walletHistory": "Wallet History",
  "account.transactionType": "Transaction Type",
  "account.amount": "Amount",
  "account.status": "Status",
  "account.details": "Details",
  "account.transactionDate": "Date",
  "account.loyaltyPoints": "Loyalty Points",
  "account.referralProgram": "Referral Program",
  "account.affiliateProgram": "Affiliate Program",
  "account.myPoints": "My Points",
  "account.point": "point",
  "account.redeem": "Redeem",
  "account.earnedPoint": "Earned point",
  "account.expiredSoon": "Expired soon",
  "account.expiredPoints": "Expired Points",
  "account.pointsHistory": "Points History",
  "account.expireOn": "Expired on",
  "account.redeemPoints": "Redeem Points to My Wallet",
  "account.pointsLabel": "Points to redeem",
  "account.type": "Type",
  "account.points": "Points",
  "account.deliveryCode": "Delivery Code",
  "account.orderInvoice": "Order Invoice",
  "account.deliveryEta": "Time Of Arrival",
  "account.deliveryEtaDesc": "Estimate time of arrival",
  "account.copiedSuccess": "Copied Successfully",
  "account.uploadPhoto": "Upload Photo",
  "account.currentCommission": "Current commission rate",
  "account.affiliateRule": "Affiliate Rule",
  "account.referralCode": "Referral Code",
  "account.totalPoints": "Total Point",
  "account.firstPurchase": "First Purchase",
  "account.referralDesc": "Unique Referral code can be copied & shared with others to refer them to the website.",

  "branch.closed": "Closed",
  "branch.minimum": "Minimum",
  "branch.minutes": "Min",
  "branch.free": "Free",
  "account.commissionHistory": "Commission history",
  "account.detailsCommissionEarned": "details for each commission earned",
  "account.earned": "Earned",
  "account.purchaseAmount": "Purchase Amount",
  "account.commissionRate": "Commission Rate",
  "home.noServiceData": "Sorry, we don't deliver to your area now!",

  "influencer.followers": "Followers",
  "influencer.follow": "Follow",
  "influencer.shareCollection": "Share"
}
